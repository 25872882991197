import React from 'react';
import { useMeasure } from 'react-use';
import classNames from 'classnames';
import useDarkMode from '../../hooks/useDarkMode';
import './Footer.css';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();
	const [ref, { height }] = useMeasure();

	const root = document.documentElement;
	root.style.setProperty('--footer-height', `${height}px`);

	const { darkModeStatus } = useDarkMode();

	return (
		<footer ref={ref} className='footer  ftr-mr' 
		// style={{ margin: 0 }}
		>
			<div className='container-fluid'>
				<div className='row justify-content-between align-items-center'>
					<div className='col-auto col-6 col-md-auto'>
						<span className='fw-light copyright-text'>
							{`${t('Copyright')} © 2024 - ${t('Version')} 2.0.0`}
						</span>
					</div>
					<div className='col-auto col-6 col-md-auto text-end'>
						<a
							href='/'
							className={classNames('text-decoration-none', {
								'link-dark': !darkModeStatus,
								'link-light': darkModeStatus,
							})}>
							<small className='fw-bold copyright-text'>
								SchedulerDesk Corporation
							</small>
						</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
