import React from 'react';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import Badge from '../../components/bootstrap/Badge';
import { priceFormat } from '../../helpers/helpers';
import { layoutMenu } from '../../menu';
import '../common/Card.css';
import useDarkMode from '../../hooks/useDarkMode';
import PlaceholderImage from '../../components/extras/PlaceholderImage';
import { useTranslation } from 'react-i18next';

const CommonGridProductItemSwl = ({
	// eslint-disable-next-line react/prop-types
	id,
	// eslint-disable-next-line react/prop-types
	name,
	// eslint-disable-next-line react/prop-types
	category,
	// eslint-disable-next-line react/prop-types
	img,
	// eslint-disable-next-line react/prop-types
	price,
	currency,
}) => {
	const { t } = useTranslation();
	const { darkModeStatus } = useDarkMode();

	return (
		<Card
			className='card-cus'
			// style={{
			// 	width: '90%',
			// 	cursor: 'pointer',
			// }}
			>
			<CardHeader className='card-hdr' 
			// style={{ paddingBottom: 0,paddingTop: 0 }}
			>
				<CardLabel className='card-lbl' 
				// style={{ textAlign: 'left', width: '100%' }}
				>
					<CardTitle className='card-tltl'
					// style={{ fontSize: '15px' }}
					>
						<>{name}</>
					</CardTitle>
					<CardSubTitle className='card-subtltl'
					// style={{ fontSize: '10px' }}
					>{category}</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody className='card-bdy'
				// style={{
				// 	paddingBottom: 0,
				// 	paddingTop: 0,
				// 	position: 'relative',
				// }}
				>
				{img ? (
					<img className='card-bdy-img'
						// style={{
						// 	objectFit: 'cover',
						// 	width: '100%',
						// 	height: '300px',
						// 	borderRadius: '12px',
						// 	paddingBottom: 0,
						// }}
						src={img}
						alt=''
						// width={100}
						// height={100}
						// className='mx-auto d-block img-fluid'
					/>
				) : (
					<PlaceholderImage
						width={150}
						height={150}
						className='mx-auto d-block img-fluid mb-3 rounded'
					/>
				)}
				{price && (
					<Badge
						color='success'
						className='ms-2 card-badge'
						// width='150px'
						// height='150px'
						// style={{
						// 	position: 'absolute',
						// 	bottom: '5px',
						// 	right: '20px',
						// 	borderRadius: '0 0 0 0',
						// 	fontSize: '12px',
						// }}
						>
						{currency + ' ' + priceFormat(price)}
					</Badge>
				)}
			</CardBody>
			<CardFooter
				className='shadow-3d-container card-ftr'
				// style={{
				// 	paddingTop: 0,
				// 	paddingBottom: '8px',
				// 	marginBottom: 0,
				// }}
				>
				<Button
					color='dark'
					// style={{
					// 	fontSize: '12px',
					// 	paddingTop: '4px',
					// 	paddingBottom: '4px',
					// }}
					// className={`w-100 mb-4-${darkModeStatus ? 'light' : 'dark'}`}
					className={`w-100 mb-4-${darkModeStatus ? 'light' : 'dark'} card-ftr-btn`}
					size='lg'
					tag='a'
					to={`../${layoutMenu.cbookingPages.subMenu.view_servwl.path}/${id}`}>
					{t('View Service')}
				</Button>
			</CardFooter>
		</Card>
	);
};

export default CommonGridProductItemSwl;
